/* purgecss start ignore */
@import "tailwindcss/base";
/* purgecss end ignore */

@import "tailwindcss/components";

@import "tailwindcss/utilities";

body {
  @apply bg-gray-100 text-gray-900;
}

.h1 {
  @apply text-4xl font-bold tracking-wide;
}

.h2 {
  @apply text-xl font-bold;
}

.text-input {
  @apply appearance-none border rounded py-2 px-3 leading-tight;
}

.text-input:focus {
  @apply outline-none shadow;
}

.emoji {
  background-size: contain;
  display: inline-block;
  margin-bottom: -3px;
}

/* Dark */

/* body {
  background-color: #333;
} */

.lessp {
  padding-left: 10px;
  padding-right: 10px;
}

.fw{
width: 100%;
}

.header-cont {
  height: 50px;
  background-color: #2b6cb0;
  color: #fff;
  display: flex;
  align-items: center;
}

.top--3 {
  top: -3px;
}

.border-blue-800,
.border-green-800,
.border-red-800,
.border-red-900 {
  border: 0;
}